const isDev = process.env.REACT_APP_ENV === "development";
const isStaging = process.env.REACT_APP_DEBUG === "true" || process.env.REACT_APP_ENV === "staging";

export const BASE_URL = isDev
  ? "http://localhost:3041"
  : isStaging
  ? "https://admin.notepay-dev.com"
  : window.location.origin;

export const API_BASE_URL = `${BASE_URL}/api`;

export const AUTH_CLIENT_URL = `${BASE_URL}/auth/v1`;

export const AUTH_BASE_URL = isDev
  ? "http://localhost:5100"
  : isStaging
  ? "https://auth.notepay-dev.com"
  : window.location.origin.replace("admin", "auth");

export const EXPENSES_BASE_URL = isDev
  ? "http://localhost:3000"
  : isStaging
  ? "https://app.notepay-dev.com"
  : window.location.origin.replace("admin", "app");

export const RECEIPTS_URL = isDev
  ? "http://localhost:9008"
  : isStaging
  ? "https://receipts.notepay-dev.com"
  : process.env.REACT_APP_RECEIPTS_URL || "https://receipts.notepay.com.br";

export const WSS_URL = isDev
  ? "ws://localhost:7500"
  : isStaging
  ? "wss://ws.notepay-dev.com"
  : `wss://${window.location.hostname.replace("admin", "ws")}`;
